import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Table,
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import axios from "axios";
import { getAllData, getUser } from "services/user.services";
import { useParams } from "react-router-dom";

const Document = () => {
  const { id_imovel, nameRuralPropertie } = useParams();
  const apiDocuments = `${process.env.REACT_APP_API_URL}/api/documentos/`;
  const [descricao, setDescricao] = useState("");
  const [file, setFile] = useState("");
  const [documents, setDocuments] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    updateDocuments();
  }, [id_imovel]);

  const updateDocuments = () => {
    let initialDocuments =getUser().documentos.filter(document => 
      id_imovel ? document.id_imovel === parseInt(id_imovel) : document.id_imovel === null
    )
    setDocuments(initialDocuments);
  }

  const handleChangeData = async (event) => {
    event.preventDefault();

    if (!file) {
      toast.error(`Nenhum arquivo selecionado`);
      return;
    }

    setIsLoading(true);

    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("descricao", descricao);
      formData.append("id_imovel", id_imovel ? id_imovel : null);
      await axios.post(apiDocuments, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      await getAllData();
      updateDocuments();
      toast.success(`Documento enviado com sucesso.`);
      setDescricao("");
      setFile("");
    } catch (error) {
      if (error?.response?.status !== 200) {
        toast.error(`Erro: ${error.response.data.message}`);
      }
      console.error("Erro:", error);
    } finally {
      setIsLoading(false); 
    }
  };

  const handleDelete = async (id_documentos) => {
    try {
      await axios.delete(
        `${apiDocuments}${id_documentos}`
      );
      toast.success(`Documento deletado com sucesso.`);
      await getAllData();
      updateDocuments();
    } catch (error) {
      if (error?.response?.status !== 200) {
        toast.error(`Erro: ${error.response.data.message}`);
      }
      console.error("Erro:", error);
    }
  };

  return (
    <>
      <UserHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <Form onSubmit={handleChangeData}>
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">
                        {id_imovel ? `Documentos para o imóvel: ${nameRuralPropertie ? nameRuralPropertie : id_imovel}` : "Documentos"}
                      </h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-nome-endereco"
                          >
                            Descrição
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-nome-endereco"
                            placeholder="CNH"
                            type="text"
                            value={descricao}
                            onChange={(e) => setDescricao(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-nome-endereco"
                          >
                            Upload de Arquivo
                          </label>
                          <Input
                            className="form-control-alternative"
                            type="file"
                            name="file"
                            id="file"
                            onChange={(e) => setFile(e.target.files[0])}
                            accept=".pdf, .doc, .docx, .jpg, .jpeg, .png, .gif, .xls, .xlsx"
                            required
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Button color="info" type="submit" className="ml-auto" disabled={isLoading}>
                      {isLoading ? "Enviando..." : "Adicionar"}
                    </Button>
                  </div>
                </CardBody>
              </Form>
            </Card>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Documentos adicionados</h3>
                  </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <colgroup>
                  <col style={{ width: "10%" }} />
                  <col style={{ width: "85%" }} />
                  <col
                    style={{ width: "5%", textAlign: "center", color: "red" }}
                  />
                </colgroup>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Descrição</th>
                    <th scope="col">Arquivo</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {documents && documents.length ? (
                    documents.map((document, index) => (
                      <tr key={index}>
                        <th scope="row">{document.descricao}</th>
                        <td>
                          <a
                            href={apiDocuments + document.id}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Abrir
                          </a>
                        </td>
                        <td
                          style={{
                            width: "5%",
                            textAlign: "center",
                            color: "red",
                          }}
                        >
                          <i
                            className="fas fa-trash cursor-pointer"
                            onClick={() => handleDelete(document.id)}
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan={7}
                        style={{
                          width: "5%",
                          textAlign: "center",
                          color: "red",
                          fontWeight: "bolder",
                        }}
                      >
                        Nenhum registro!
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Document;
